<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <pd-offer-form-box
        list-url="/offers"
        :id="id"
        offer-type="offer"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
      >
      </pd-offer-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import PdOfferFormBox from '../../components/PdOfferFormBox.vue';

export default {
  name: 'PdForm',
  components: {
    PdOfferFormBox
  },
  async mounted() {
    if (this.$route.name === 'offer-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'offer',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Offer' : 'Update Offer';
    },
    ...mapState('pdOffer', ['loading'])
  },
  methods: {
    ...mapActions('pdOffer', ['getOne', 'postOne', 'patchOne']),
    onAdd({ offer }) {
      this.postOne({
        offer,
        router,
        redirectUrl: '/offers'
      });
    },
    onEdit({ offer }) {
      this.patchOne({
        type: 'offer',
        id: this.id,
        offer,
        router,
        redirectUrl: '/offers'
      });
    }
  }
};
</script>
