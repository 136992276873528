<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Offer
            <span class="text-danger">*</span>
          </template>

          <b-form-textarea
            id="input-name"
            type="text"
            v-model="form.name"
            placeholder="Enter Offer Description"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
          ></b-form-textarea>
          <b-form-text id="input-name-help">Offer description should not be more than 75 characters.</b-form-text>

          <b-form-invalid-feedback id="input-name-invalid">Please enter offer name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Active
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-status"
            placeholder="Select Active Type"
            v-model="form.active"
            :options="['True', 'False']"
            :state="$v.form.active.$dirty ? !$v.form.active.$error : null"
          ></multiselect>
        </b-form-group>

        <div class="calender">
          <b-form-datepicker
            id="start-datepicker"
            placeholder="Start Date"
            :min="minDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="form.startDate"
            class="ml-2 mb-2"
          ></b-form-datepicker>
          <b-form-datepicker
            id="end-datepicker"
            placeholder="End Date"
            :min="minDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="form.endDate"
            class="ml-2 mb-2"
          ></b-form-datepicker>
        </div>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Region Ids
            <span class="text-danger">*</span>
          </template>

          <multiselect
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            v-model="regionids"
            placeholder="Select region Ids"
            label="text"
            :options="regions"
          >
          </multiselect>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Cluster Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            v-model="clusterids"
            placeholder="Select Cluster Ids"
            label="text"
            :options="clusters"
          >
          </multiselect>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="storeids"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="stores"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          ></multiselect>
          <b-form-text id="input-role-help"
            >Note: The first selected store id will be the primary store id of CheckList for all
            operations.</b-form-text
          >
          <b-button  @click="uploadStores" variant="success">Upload Stores</b-button>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Format
            <span class="text-danger">*</span>
          </template>

          <multiselect
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            v-model="form.formats"
            placeholder="Select Store Format"
            label="text"
            :options="formats"
          >
          </multiselect>
        </b-form-group>

        <template v-if="storeIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ storeIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="regionIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ regionIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="clusterIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ clusterIdError }}</b-col>
          </b-row>
        </template>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/offers` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'PdOfferFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    offerType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        }
      ],
      title: '',
      formLoaded: true,
      regions: [],
      clusters: [],
      formats: [],
      stores: [],
      storeids: [],
      clusterids: [],
      regionids: [],
      form: {
        name: null,
        active: 'True',
        startDate: null,
        endDate: null,
        storeids: [],
        regionids: [],
        formats: [],
        clusterids: []
      },
      storeIdError: null,
      clusterIdError: null,
      regionIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      minDate: moment().format('YYYY-MM-DD')
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      active: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.active.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Offer' : 'Update Offer';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('pdOffer', ['loading', 'offer']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList'])
  },
  methods: {
    ...mapActions('pdOffer', ['listOffers', 'getOne', 'postOne', 'patchOne']),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),

    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const storeids = [];
      this.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.form.formats.map(({ value }) => formats.push(value));

      const offer = {
        name: this.form.name,
        active: this.form.active === 'True',
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        storeids,
        clusters: clusters || [],
        regions: regions || [],
        formats: formats || []
      };
      if (this.formType === 'new') {
        this.$emit('add', { offer });
      } else {
        this.$emit('edit', { offer });
      }
      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
     createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      } is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
                return null;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },
  watch: {
    offer(newValue) {
      if (!this.offer.id) {
        return;
      }
      // Loaded offer, assign to form
      this.form.name = newValue.name;
      this.form.active = newValue.active;
      this.form.startDate = moment(newValue.startDate).format('YYYY-MM-DD');
      this.form.endDate = moment(newValue.endDate).format('YYYY-MM-DD');
      this.storeids = newValue.storeids.map(store => ({ text: store, value: store }));
      this.regionids = newValue.regions.map(region => ({ text: region, value: region }));
      this.clusterids = newValue.clusters.map(cluster => ({ text: cluster, value: cluster }));
      this.form.formats = newValue.formats.map(format => ({ text: format, value: format }));

      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    clusterList(newValue) {
      this.clusters = newValue;
    },
    regionList(newValue) {
      this.regions = newValue;
    },
    formatList(newValue) {
      this.formats = newValue;
    },
    storeList(newValue) {
       let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.value
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
.date {
  margin-left: -10px;
}
.upload {
  margin-top: 8px;
}
</style>
